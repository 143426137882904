document.addEventListener( "DOMContentLoaded", () => {
	const mobileNavigationMenuSelector = ".mobile-navigation-menu"

	const menu = new Mmenu( mobileNavigationMenuSelector, {
		extensions: [
			"position-back",
			"position-right",
		],
		navbars: [],
		navbar: {
			add: false,
		},
		screenReader: {
			aria: true,
			text: true,
		}
	});

	$( ".menu-icon" ).click( () => {
		if ( $( mobileNavigationMenuSelector ).hasClass( "mm-opened" ) ) {
			menu.API.close()
		}
		else {
			menu.API.open()
		}
	});

	//This block of code removes the mm-navbar__title element, which caused the "Clickable controls should be keyboard accessible" and "Clickable controls should have an ARIA role" WCAG errors in SortSite
	const mobileNavTitle = document.getElementsByClassName('mm-navbar__title');
	while (mobileNavTitle[0]) {
		mobileNavTitle[0].parentNode.removeChild(mobileNavTitle[0]);
	}

	window.addEventListener("scroll", () => {
		let thinNav = document.getElementById("thin-top-nav");
		let logo = document.getElementById("logo");
		let socialMediaUL = document.getElementById("socialMediaUL");
		let registerBtn = document.getElementById("registerBtn");
		let whiteNavBg = document.getElementById("whiteNavBg");

		if (window.scrollY > 50) {
			thinNav.style.display = "none";

			if ($(window).width() >= 991) {
				logo.classList.remove("grow-element");
				logo.classList.add("shrink-element-20-percent");

				socialMediaUL.classList.remove("grow-element");
				socialMediaUL.classList.add("shrink-element-10-percent");

				registerBtn.classList.remove("grow-element");
				registerBtn.classList.add("shrink-element-10-percent");

				whiteNavBg.classList.remove("increase-padding");
				whiteNavBg.classList.add("reduce-padding");
			}
			else {
				logo.classList.remove("grow-element");
				logo.classList.remove("shrink-element-20-percent");

				socialMediaUL.classList.remove("grow-element");
				socialMediaUL.classList.remove("shrink-element-10-percent");

				registerBtn.classList.remove("grow-element");
				registerBtn.classList.remove("shrink-element-10-percent");

				whiteNavBg.classList.remove("reduce-padding");
			}
		}
		else {
			if (thinNav != null) {
				thinNav.style.display = "block";

				if ($(window).width() >= 991) {
					logo.classList.remove("shrink-element-20-percent");
					logo.classList.add("grow-element");

					socialMediaUL.classList.remove("shrink-element-10-percent");
					socialMediaUL.classList.add("grow-element");

					registerBtn.classList.remove("shrink-element-10-percent");
					registerBtn.classList.add("grow-element");

					whiteNavBg.classList.remove("reduce-padding");
				}
			}
		}
	});
});
